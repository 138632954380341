import React, { useState } from "react";
import Breadcrumbsbanner from "../breadcumbbanner/breadcumbsbanner";

import { useForm } from "react-hook-form";
import {
  bloodGroup,
  genderList,
  bibLocation,
  tShirtSizes,
  dateconvert,
  accommodation,
  ageCalculator,
  datecalendarconvert,
  centerList,
} from "../../../assets/data/commonData";

import deletebin from "../../../assets/images/myregisteration/trash-fill.svg";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

// import Racemap  from "../../../components/raceinfo/raceinfoblock";
// import logOutimg from "../../../assets/images/logOut.png";
import pathhead from "../../../assets/images/raceinfo/path.png";

import gross from "../../../assets/images/raceinfo/gross.png";

import routemapicon from "../../../assets/images/raceinfo/routemap-icon.png";

import tshirt from "../../../assets/images/raceinfo/t-shirt.png";

import medal from "../../../assets/images/raceinfo/medal.png";
import clock from "../../../assets/images/raceinfo/clock.png";

import breakfast from "../../../assets/images/raceinfo/breakfast.png";

import ecertificate from "../../../assets/images/raceinfo/certificate.png";

import { Link, navigate } from "gatsby";
import { useEffect } from "react";
import KanhaRunService from "../../services/kanha.service";
import { Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import backn from "../../../assets/images/backIconSummary.svg";
import "react-toastify/dist/ReactToastify.css";
import config from "../../../assets/config";
import { CreateImageFromInitials, GetRandomColor } from "../../utils/common";
import moment from "moment";
import { lStorage } from "../../utils/storage";
import countriesData from "../../../pages/country_code.json";
import RegisterPopup from "../../home/registerpopup";

export default function RacePrice() {
  const datePickerId = new Date().toISOString().slice(0, 10);
  const minDateValue = "1950-01-06";
  const [pList, setPlist] = useState(lStorage.get("list"));
  const skuList = lStorage.get("skuData");
  const [formData, setFormData] = useState(pList);
  const [raceValue, setRaceValue] = useState([]);
  const [accommidationStatus, setAccommidationStatus] = useState("");
  const [raceList, setRaceList] = useState([]);
  const [raceError, setRaceError] = useState(false);
  const [sameNumberError, setSameNumber] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [secretKey, setSecretKey] = useState();
  const [isUpdated, setIsUpdated] = useState(false);
  const [isDeleted, setIsDeleted] = useState();
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [selectedCityName, setSelectedCityName] = useState();
  const [selectedState, setSelectedState] = useState();
  const [raceType, setRaceType] = useState();
  const [globalRun, setGlobalRun] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [islogin, setIsLogin] = useState(true);
  const isSpecialDonation = lStorage.get("specialDonation");
  const userData = lStorage.get("userInfo");
  console.log("pList", pList?.length);

  async function handleRaceCategory(race_category_id) {
    let selectedRace = {};
    try {
      const response = await kanhaApi.getracecategory();
      if (response.data.isError === false || response.data.statusCode === "200") {
        if (response.data.isError === false) {
          const raceList = response.data.data.filter(race => race.race_category_id === race_category_id);
          if (raceList.length > 0) {
            selectedRace.race_category_id = raceList[0].race_category_id;
            selectedRace.race_category_name = raceList[0].race_name;
            selectedRace.race_km = raceList[0].race_slug;
            localStorage.setItem("selectedRace", JSON.stringify(selectedRace));
              if (userData !== null && userData !== undefined) {
                  navigate("/basicformpage");
                } else {
                  setModalShow(true);
                }
          } else {
            console.error("No race found for race_category_id:", race_category_id);
          }
        } else {
          console.error("Error:", response.data.errorMessage);
        }
      }
    } catch (error) {
      console.error("Network error:", error);
      toast.error("Invalid Token");
      logOut();
    }
  }
  
  

  const closeModal = () => {
    setModalShow(false);
    window.location.reload();
  };

  const kanhaApi = new KanhaRunService();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    setFocus,
    clearErrors,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const prevData = formData == null ? [] : formData;
    const datass = data;
    console.log(data, "hhgghgh");

    let split = data.race_category_id.split(",");
    let v1 = split[0];
    let v2 = split[1];
    let v3 = split[2];
    const years = ageCalculator(data.dob);
    let valid = "";
    if (v1 <= 4) {
      valid =
        years < 8 && v1 == 1
          ? "valid"
          : years >= 8 && (v1 == 2 || v1 == 1)
          ? "valid"
          : years >= 15 && (v1 == 3 || v1 == 2 || v1 == 1)
          ? "valid"
          : years >= 18 && (v1 == 4 || v1 == 3 || v1 == 2 || v1 == 1)
          ? "valid"
          : "notvalid";
    } else {
      valid = "valid";
    }
    let mobileValid =
      data.contact_number !== data.emergence_contact_number
        ? "valid"
        : "mobile number same";
    if (valid === "valid" && mobileValid === "valid") {
      setAccommidationStatus("");
      const birdValues =
        raceList.earlybird == "YES"
          ? "hfn-special-discount"
          : "hfn-non-early-bird";
      const categoryVal =
        data.overnight_accommodation == "Y" ? "with-dorm" : "without-dorm";
      let concatValues = "";
      if (v1 <= 4) {
        concatValues = [v2, categoryVal].join("-");
      } else {
        concatValues = [v2].join("-");
      }

      let removeData = "";

      if (config.environment === "staging") {
        removeData = skuList && skuList.filter((e) => e.code == concatValues);
      } else {
        removeData = skuList && skuList.filter((e) => e.code == concatValues);
      }
      if (JSON.parse(localStorage.getItem("list"))) {
        const applyData = JSON.parse(localStorage.getItem("list"));
        /*datass["discount_code"] =applyData[0].discount_code ;
        datass["discount_name"]=applyData[0].discount_name;
        datass["discount_price"] =applyData[0].discount_price;*/
      }

      const newData = {
        ...datass,
        dob: dateconvert(datass.dob),
        race_category_id: v1,
        sku_id: removeData[0].id,
        race_km: v2,
        sku_title: removeData[0].title,
        race_price: Math.round(
          removeData[0].rate +
            (removeData[0].gstPercentage / 100) * removeData[0].rate
        ),
        race_category_name: v3,
        secretId: Math.floor(Math.random() * Date.now()),
        discount_price: 0,
        final_amount: Math.round(
          removeData[0].rate +
            (removeData[0].gstPercentage / 100) * removeData[0].rate
        ),
        discount_code: "",
        discount_name: "",
      };
      if (isEdit === false) {
        await prevData.push(newData);
        lStorage.set("list", prevData);
        await setFormData(prevData);
        // window.scrollTo(0, 0);
        toast.success("Participant added successfully");
        setTimeout(() => {
          navigate("/successfulparticipants");
        }, 1000);
        await reset();
      } else if (isEdit === true) {
        var newIndex;
        (() => {
          pList?.map((item, index) => {
            if (secretKey === item.secretId) {
              newIndex = index;
              return index;
            }
          });
          pList?.splice(newIndex, 1, newData);
        })();

        lStorage.set("list", pList);
        setIsUpdated(true);
        toast.success("Participant updated successfully");
        setTimeout(() => {
          navigate("/successfulparticipants");
        }, 1000);
        setIsEdit(false);
        lStorage.remove("editSecretId");
        await reset();
      }
    } else {
      if (mobileValid === "mobile number same") {
        // toast.error(
        //   // "Contact number and Emergency contact number should not be same"
        // );
        setSameNumber(true);
        window.scrollTo(0, 0);
      } else {
        // toast.error(
        //   "Your age  is not eligible for participant in this selected Race"
        // );
        setRaceError(true);
        setFocus("race_category_id");
      }
    }
  };

  const getraceList = () => {
    kanhaApi
      .getracecategory()
      .then((response) => {
        if (
          response.data.isError === false ||
          response.data.statusCode === "200"
        ) {
          if (response.data.isError === false) {
            setRaceList(response.data);
          } else {
            // console.log("error");
          }
          //  navigate("/basicformpage");
        }
      })
      .catch(function (error) {
        toast.error("Invalid Token");
        logOut();
      });
  };

  const raceListsss = () => {
    kanhaApi
      .raceList()
      .then((response) => {
        if (response.status == "200") {
          lStorage.set("skuData", response.data.sku);
        }
      })
      .catch(function (error) {
        toast.error("something went wrong");
        logOut();
      });
  };

  const logOut = () => {
    lStorage.remove("userInfo");
    lStorage.remove("verification_id");
    lStorage.remove("list");
    lStorage.remove("globalRun");
    lStorage.remove("appliedCouponCode");
    navigate("/");
  };
  const backTo = () => {
    navigate("/myregisterationlist");
  };

  const deleteData = async (data) => {
    let removeData = formData && formData.filter((e) => e !== data);
    lStorage.set("list", removeData);
    const parseList = removeData;
    await setFormData(parseList);
  };

  useEffect(() => {
    localStorage.setItem("selectedRace", "");
    const toEditSecretId = lStorage.get("editSecretId");
    if (toEditSecretId !== undefined || null) {
      const editDataObject = lStorage.get("list")?.filter((item) => {
        if (toEditSecretId === item.secretId) {
          return item;
        }
      })?.[0];
      setTimeout(() => {
        editData(editDataObject);
        setIsEdit(true);
      }, 650);
    }

    const isGlobalRun = lStorage.get("globalRun");
    if (isGlobalRun === 1) {
      setGlobalRun(1);
    }

    const selectedLocation = lStorage.get("selectedLocation");
    setSelectedCountry(selectedLocation.country);
    setSelectedCity(selectedLocation.city_id);
    setSelectedCityName(selectedLocation.city);
    setSelectedState(selectedLocation.state);
    setRaceType(selectedLocation.runType);
  }, []);

  const editData = async (data) => {
    setIsUpdated(false);
    setSecretKey(data?.secretId);
    setRaceValue(Number(data?.race_category_id));

    setValue(
      "race_category_id",
      data?.race_category_id +
        "," +
        data?.race_km +
        "," +
        data?.race_category_name
    );

    const fields = [
      "first_name",
      "last_name",
      "email_address",
      "contact_number",
      "gender",
      "blood_group",
      "eContact",
      "emergence_contact_number",
      // "country_code",
      "address",
      "name_on_bib",
      "t_shirt_size",
      "bib_collection_location",
      "overnight_accommodation",
      "sku_id",
      "race_km",
      "sku_title",
      "race_price",
      "race_category_name",
      "em_country_code",
      "countrycode",
      "organisation_name",
    ];
    fields.forEach(
      (field) => setValue(field, data?.[field]),
      setValue(
        "dob",
        data?.dob && data?.dob ? datecalendarconvert(data?.dob) : ""
      )
      // setValue(
      //   "race_category_id",
      //   data.dob && data.dob
      //     ? [
      //         {
      //           race_category_id: Number(data.race_category_id),
      //           race_name: data.race_category_name,
      //           race_price: data.race_price,
      //           race_slug: data.race_km,
      //         },
      //       ]
      //     : ""
      // )
    );

    setAccommidationStatus(data?.overnight_accommodation);
  };

  const backToList = () => {
    setIsEdit(false);
    lStorage.remove("editSecretId");
    navigate(
      formData && formData?.length > 0
        ? "/successfulparticipants"
        : "/myregisterationlist"
    );
  };

  useEffect(() => {
    setPlist(lStorage.get("list"));
  }, [isEdit]);

  useEffect(() => {
    setPlist(lStorage.get("list"));
  }, [isDeleted]);

  useEffect(() => {
    // getraceList();
    raceListsss();
  }, [formData]);

  useEffect(() => {
    if (isUpdated === true) {
      setPlist(lStorage.get("list"));
      setIsEdit(false);
    }
  }, [isUpdated]);

  useEffect(() => {
    setFormData(pList);
  }, [pList]);

  // useEffect(() => {
  //   kanhaApi
  //     .getorderlist()
  //     .then((response) => {
  //       if (
  //         response.data.isError === false ||
  //         response.data.statusCode === "200"
  //       ) {
  //         if (response?.data?.data?.length === 0) {
  //           setIsListEmpty(false);
  //         } else if (response?.data?.data?.length > 0) {
  //           setIsListEmpty(true);
  //         }
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       // logOut();
  //     });
  // }, []);

  return (
    <>
      <Breadcrumbsbanner />
      <div className="Basic-form-wrapper inner-pages pt-4">
        <div className="container-fluid">
          <div className="col-md-12">
            <div className="row">
              {formData && formData?.length > 0 && (
                <div className="p-2">
                  <Button className="btn btn-primary" onClick={backToList}>
                    <img className="img-fluid" src={backn} alt="back" />
                    {formData && formData?.length > 0
                      ? "Back to My Summary"
                      : "Back to My Registration"}
                  </Button>
                </div>
              )}

              {isListEmpty && !formData && (
                <div className="p-2">
                  <Button className="btn btn-primary" onClick={backToList}>
                    <img className="img-fluid" src={backn} alt="back" />
                    Back to My Registration
                  </Button>
                </div>
              )}

              <div className="">
                <section id="race">
                  <div className="raceinfo-wrapper subpages pt-4">
                    <div className="container-fluid">
                      <div className="row mt-2" style={{ textAlign: "center" }}>
                        {(raceType === "Global Run" ||
                          raceType === "Timed Run") && (
                          <h3 className="title-heading-h3">
                            <strong  className="title-heading-new">State: </strong>{selectedState}, <strong  className="title-heading-new">Center Name:</strong> {selectedCityName}
                          </h3>
                        )}
                        {raceType === "Overseas" && (
                          <h3 className="title-heading">Country: <span>{selectedState}</span></h3>
                        )}

                        {/* <h3 className="title-heading"> Race Type: {raceType}</h3> */}
                        <h3 className="title-heading"> RACE INFO</h3>
                      </div>

                      <div
                        className={`${
                          globalRun === 1
                            ? "grid-container mb-4 raceinfoWidthGlobalRun"
                            : "grid-container mb-4"
                        }`}
                      >
                        {selectedCountry === "Overseas" && (
                          <div className="grid-item">
                            <div className="road-arch-block"
                             onClick={() => handleRaceCategory(8)}
                             style={{
                               cursor: "pointer",
                             }}
                            >
                              <span className="top-head">
                                <img
                                  className="img-fluid"
                                  src={pathhead}
                                  alt="Before"
                                />
                              </span>
                              <div className="white-arch ">
                                <h3 className="virtual_run_txt">
                                  <span
                                    className="less-item"
                                  >
                                    Global Run
                                  </span>
                                </h3>
                                <p
                                  style={{
                                    marginTop: "5px",
                                    fontSize: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                  <b> Run from anywhere</b>
                                </p>
                                <p className="note-text">
                                  <b> Amount </b> - USD 12 (Rs. 1000)
                                </p>
                                <p className="note-text">
                                  <b> Includes:</b>
                                </p>
                                <div className="dis-icon">
                                  <span>
                                    <img
                                      className="img-fluid"
                                      src={ecertificate}
                                      alt="ecertificate"
                                    />
                                  </span>
                                </div>
                                <p className="note-content">e-Certificate</p>
                                <p className="note-content">
                                  For runners enthusiast <br></br> in other
                                  locations.
                                </p>
                                <div className="count">
                                  <Button
                                    className="btn btn-orange btn-right-order lower-case"
                                    onClick={() => handleRaceCategory(8)}
                                    style={{
                                      padding: "10px 30px",
                                      marginBottom: "20px",
                                      border: "none",
                                    }}
                                    disabled={pList?.length >= 5 ? true : false}
                                  >
                                    Register Now
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {selectedCountry === "India" && selectedCity === 1 && (
                          <div className="grid-item">
                            <div className="road-arch-block"
                             onClick={() => handleRaceCategory(1)}
                             style={{
                               cursor: "pointer",
                             }}
                            >
                              <span className="top-head">
                                <img
                                  className="img-fluid"
                                  src={pathhead}
                                  alt="Before"
                                />
                              </span>
                              <div className="white-arch">
                                <h3 className="count">
                                  1.5<span className="less-item">KM </span>
                                  <i
                                    class="fa fa-clock-o"
                                    aria-hidden="true"
                                  ></i>
                                </h3>
                                <p
                                  style={{
                                    marginTop: "5px",
                                    fontSize: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                  <b> Family Run </b>
                                </p>
                                <p className="note-text pt-1">
                                  <b style={{ display: "inline-block" }}>
                                    {" "}
                                    Amount{" "}
                                  </b>{" "}
                                  - Rs. 600
                                </p>
                                <p className="note-text">
                                  <b> Includes: </b>
                                </p>
                                <div className="dis-icon">
                                  <span>
                                    <img
                                      className="img-fluid"
                                      src={tshirt}
                                      alt="tshirt"
                                    />
                                  </span>
                                  <span>
                                    <img
                                      className="img-fluid"
                                      src={medal}
                                      alt="tshirt"
                                    />
                                  </span>
                                  <span className="tshirt-img-container">
                                    <img
                                      className="img-fluid"
                                      src={breakfast}
                                      alt="tshirt"
                                    />
                                  </span>
                                </div>
                                <p className="note-content">
                                  T-shirt, Medal, Breakfast
                                </p>
                                <p className="note-content">
                                  For minors, prior consent<br></br> of parent
                                  necessary.
                                </p>
                                <div className="count">
                                  <Button
                                    className="btn btn-orange btn-right-order lower-case"
                                    onClick={() => handleRaceCategory(1)}
                                    style={{
                                      padding: "10px 30px",
                                      marginBottom: "20px",
                                      border: "none",
                                    }}
                                    disabled={pList?.length >= 5 ? true : false}
                                  >
                                    Register Now
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {selectedCountry === "India" && selectedCity === 1 && (
                          <div className="grid-item">
                            <div className="road-arch-block"
                             onClick={() => handleRaceCategory(2)}
                             style={{
                               cursor: "pointer",
                             }}
                            >
                              <span className="top-head">
                                <img
                                  className="img-fluid"
                                  src={pathhead}
                                  alt="Before"
                                />
                              </span>
                              <div className="white-arch">
                                <h3 className="count">
                                  5<span className="less-item">KM </span>
                                  <i
                                    class="fa fa-clock-o"
                                    aria-hidden="true"
                                  ></i>
                                </h3>
                                <p
                                  style={{
                                    marginTop: "5px",
                                    fontSize: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                  {" "}
                                  <b> Timed Run </b>
                                  {""}
                                  <img
                                    className="img-fluid"
                                    width={22}
                                    src={clock}
                                    alt="Clock"
                                  />{" "}
                                </p>
                                <p className="note-text pt-1">
                                  {" "}
                                  <b style={{ display: "inline-block" }}>
                                    {" "}
                                    Amount{" "}
                                  </b>{" "}
                                  - Rs. 700
                                </p>
                                <p className="note-text">
                                  {" "}
                                  <b> Includes: </b>
                                </p>
                                {/* <p className="note-text"> <b>  What participants will get: </b>  </p> */}

                                <div className="dis-icon">
                                  <span style={{ marginLeft: "10px" }}>
                                    {" "}
                                    <img
                                      className="img-fluid"
                                      src={ecertificate}
                                      alt="tshirt"
                                    />{" "}
                                  </span>
                                  <span>
                                    {" "}
                                    <img
                                      className="img-fluid"
                                      src={tshirt}
                                      alt="tshirt"
                                    />{" "}
                                  </span>
                                  <span>
                                    {" "}
                                    <img
                                      className="img-fluid"
                                      src={medal}
                                      alt="tshirt"
                                    />{" "}
                                  </span>
                                  <span>
                                    {" "}
                                    <img
                                      className="img-fluid"
                                      src={breakfast}
                                      alt="tshirt"
                                    />{" "}
                                  </span>
                                </div>
                                <p className="note-content">
                                  e-Certificate, T-shirt, Medal, Breakfast
                                </p>
                                <p className="note-content">
                                  {" "}
                                  Age 8+ years on event day.
                                </p>
                                <div className="count">
                                  <Button
                                    className="btn btn-orange btn-right-order lower-case"
                                    onClick={() => handleRaceCategory(2)}
                                    style={{
                                      padding: "10px 30px",
                                      marginBottom: "20px",
                                      border: "none",
                                    }}
                                    disabled={pList?.length >= 5 ? true : false}
                                  >
                                    Register Now
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {selectedCountry === "India" && selectedCity === 1 && (
                          <div className="grid-item">
                            <div className="road-arch-block"
                            onClick={() => handleRaceCategory(3)}
                            style={{
                              cursor: "pointer",
                            }}
                            >
                              <span className="top-head">
                                <img
                                  className="img-fluid"
                                  src={pathhead}
                                  alt="Before"
                                />
                              </span>
                              <div className="white-arch">
                                <h3 className="count">
                                  10<span className="less-item">KM </span>
                                </h3>

                                <p
                                  style={{
                                    marginTop: "5px",
                                    fontSize: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                  {" "}
                                  <b> Timed Run </b>
                                  {""}
                                  <img
                                    className="img-fluid"
                                    width={22}
                                    src={clock}
                                    alt="clock"
                                  />{" "}
                                </p>
                                <p className="note-text pt-1">
                                  {" "}
                                  <b> Amount </b> - Rs. 800
                                </p>
                                <p className="note-text">
                                  {" "}
                                  <b> Includes: </b>
                                </p>
                                <div className="dis-icon">
                                  <span style={{ marginLeft: "10px" }}>
                                    {" "}
                                    <img
                                      className="img-fluid"
                                      src={ecertificate}
                                      alt="tshirt"
                                    />{" "}
                                  </span>
                                  <span>
                                    {" "}
                                    <img
                                      className="img-fluid"
                                      src={tshirt}
                                      alt="tshirt"
                                    />{" "}
                                  </span>
                                  <span>
                                    {" "}
                                    <img
                                      className="img-fluid"
                                      src={medal}
                                      alt="tshirt"
                                    />{" "}
                                  </span>
                                  <span>
                                    {" "}
                                    <img
                                      className="img-fluid"
                                      src={breakfast}
                                      alt="tshirt"
                                    />{" "}
                                  </span>
                                </div>
                                <p className="note-content">
                                  e-Certificate, T-shirt, Medal, Breakfast
                                </p>
                                <p className="note-content">
                                  {" "}
                                  Age 15+ years on event day.{" "}
                                </p>
                                <div className="count">
                                  <Button
                                    className="btn btn-orange btn-right-order lower-case"
                                    onClick={() => handleRaceCategory(3)}
                                    style={{
                                      padding: "10px 30px",
                                      marginBottom: "20px",
                                      border: "none",
                                    }}
                                    disabled={pList?.length >= 5 ? true : false}
                                  >
                                    Register Now
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {selectedCountry === "India" && selectedCity === 1 && (
                          <div className="grid-item">
                            <div className="road-arch-block"
                             onClick={() => handleRaceCategory(4)}
                             style={{
                               cursor: "pointer",
                             }}
                            >
                              <span className="top-head">
                                <img
                                  className="img-fluid"
                                  src={pathhead}
                                  alt="Before"
                                />
                              </span>
                              <div className="white-arch">
                                <h3 className="count">
                                  21<span className="less-item">KM </span>
                                </h3>
                                <p
                                  style={{
                                    marginTop: "5px",
                                    fontSize: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                  <b> Timed Run </b>
                                  <img
                                    className="img-fluid"
                                    width={22}
                                    src={clock}
                                    alt="Clock"
                                  />
                                </p>
                                <p className="note-text pt-1">
                                  <b> Amount </b> - Rs. 900
                                </p>
                                <p className="note-text">
                                  <b> Includes: </b>
                                </p>
                                <div className="dis-icon">
                                  <span style={{ marginLeft: "10px" }}>
                                    <img
                                      className="img-fluid"
                                      src={ecertificate}
                                      alt="tshirt"
                                    />
                                  </span>
                                  <span>
                                    <img
                                      className="img-fluid"
                                      src={tshirt}
                                      alt="tshirt"
                                    />
                                  </span>
                                  <span>
                                    <img
                                      className="img-fluid"
                                      src={medal}
                                      alt="tshirt"
                                    />
                                  </span>
                                  <span>
                                    <img
                                      className="img-fluid"
                                      src={breakfast}
                                      alt="tshirt"
                                    />
                                  </span>
                                </div>
                                <p className="note-content">
                                e-Certificate, T-shirt, Medal, Breakfast
                                </p>
                                <p className="note-content">
                                  {" "}
                                  Age 18+ years on event day.
                                </p>
                                <div className="count">
                                  <Button
                                    className="btn btn-orange btn-right-order lower-case"
                                    onClick={() => handleRaceCategory(4)}
                                    style={{
                                      padding: "10px 30px",
                                      marginBottom: "20px",
                                      border: "none",
                                    }}
                                    disabled={pList?.length >= 5 ? true : false}
                                  >
                                    Register Now
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {selectedCountry === "India" && selectedCity !== 1 && (
                          <div className="grid-item">
                            <div className="road-arch-block"
                             onClick={() => handleRaceCategory(5)}
                             style={{
                               cursor: "pointer",
                             }}
                            >
                              <span className="top-head">
                                <img
                                  className="img-fluid"
                                  src={pathhead}
                                  alt="Before"
                                />
                              </span>
                              <div className="white-arch">
                              <h3 className="virtual_run_txt">
                                  <span
                                    className="less-item"
                                  >
                                    Global Run
                                  </span>
                                </h3>
                                <p
                                  style={{
                                    marginTop: "5px",
                                    fontSize: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                  <b> Run from anywhere</b>
                                </p>
                                <p className="note-text">
                                  <b> Amount </b> - Rs. 149/-
                                </p>
                                <p className="note-text">
                                  <b> Includes:</b>
                                </p>
                                <div className="dis-icon">
                                  <span>
                                    <img
                                      className="img-fluid"
                                      src={ecertificate}
                                      alt="ecertificate"
                                    />
                                  </span>
                                </div>
                                <p className="note-content">e-Certificate</p>
                                <p className="note-content">
                                  For runners enthusiast <br></br> in other
                                  locations.
                                </p>
                                <div className="count">
                                  <Button
                                    className="btn btn-orange btn-right-order lower-case"
                                    onClick={() => handleRaceCategory(5)}
                                    style={{
                                      padding: "10px 30px",
                                      marginBottom: "20px",
                                      border: "none",
                                    }}
                                    disabled={pList?.length >= 5 ? true : false}
                                  >
                                    Register Now
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {selectedCountry === "India" && selectedCity !== 1 && (
                          <div className="grid-item">
                            <div className="road-arch-block"
                            onClick={() => handleRaceCategory(6)}
                            style={{
                              cursor: "pointer",
                            }}
                            >
                              <span className="top-head">
                                <img
                                  className="img-fluid"
                                  src={pathhead}
                                  alt="Before"
                                />
                              </span>
                              <div className="white-arch ">
                                <h3 className="virtual_run_txt">
                                  <span
                                    className="less-item"
                                  >
                                    Global Run
                                  </span>
                                </h3>
                                <p
                                  style={{
                                    marginTop: "5px",
                                    fontSize: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                  <b> Run from anywhere</b>
                                </p>
                                <p
                                  className="note-text"
                                  style={{ display: "flex" }}
                                >
                                  <b> Amount </b> - Rs. 399/-
                                </p>
                                <p className="note-text">
                                  <b> Includes:</b>
                                </p>
                                <div className="dis-icon ml-1 p-0">
                                  <span style={{ marginLeft: "4px" }}>
                                    <img
                                      className="img-fluid"
                                      src={ecertificate}
                                      alt="ecertificate"
                                    />
                                  </span>
                                  <span>
                                  <img
                                      className="img-fluid"
                                      src={tshirt}
                                      alt="tshirt"
                                    />
                                  </span>
                                </div>
                                <p className="note-content">
                                  e-Certificate, T-shirt
                                </p>
                                <p className="note-content">
                                  For runners enthusiast <br></br>in other
                                  locations
                                </p>
                                <div className="count">
                                  <Button
                                    className="btn btn-orange btn-right-order lower-case"
                                    onClick={() => handleRaceCategory(6)}
                                    style={{
                                      padding: "10px 30px",
                                      marginBottom: "20px",
                                      border: "none",
                                    }}
                                    disabled={pList?.length >= 5 ? true : false}
                                  >
                                    Register Now
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {selectedCountry === "India" && selectedCity !== 1 && (
                          <div className="grid-item">
                            <div className="road-arch-block" 
                            onClick={() => handleRaceCategory(7)}
                            style={{
                              cursor: "pointer",
                            }}
                            >
                              <span className="top-head">
                                <img
                                  className="img-fluid"
                                  src={pathhead}
                                  alt="Before"
                                />
                              </span>
                              <div className="white-arch ">
                                <h3 className="virtual_run_txt">
                                  <span
                                    className="less-item"
                                  >
                                    Global Run
                                  </span>
                                </h3>
                                <p
                                  style={{
                                    marginTop: "5px",
                                    fontSize: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                  <b> Run from anywhere</b>
                                </p>
                                <p
                                  className="note-text"
                                  style={{ display: "flex" }}
                                >
                                  <b> Amount </b> - Rs. 599/-
                                </p>
                                <p className="note-text">
                                  <b> Includes:</b>
                                </p>
                                <div className="dis-icon ml-1 p-0">
                                  <span>
                                    <img
                                      className="img-fluid"
                                      src={ecertificate}
                                      alt="ecertificate"
                                    />
                                  </span>
                                  <span>
                                    <img
                                      className="img-fluid"
                                      src={tshirt}
                                      alt="tshirt"
                                    />
                                  </span>
                                  <span>
                                    <img
                                      className="img-fluid"
                                      src={medal}
                                      alt="medal"
                                    />
                                  </span>
                                </div>
                                <p className="note-content">
                                  e-Certificate, T-shirt, Medal 
                                </p>
                                <p className="note-content">
                                  For runners enthusiast <br></br>in other
                                  locations
                                </p>
                                <div className="count">
                                  <Button
                                    className="btn btn-orange btn-right-order lower-case"
                                    onClick={() => handleRaceCategory(7)}
                                    style={{
                                      padding: "10px 30px",
                                      marginBottom: "20px",
                                      border: "none",
                                    }}
                                    disabled={pList?.length >= 5 ? true : false}
                                  >
                                    Register Now
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="row mt-4">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                        {/* {raceType !== "Timed Run" && (
                          <div
                            style={{
                              display: "block",
                              margin: "0 auto",
                              textAlign: "center",
                            }}
                          >
                            <Button
                              className="btn btn-orange btn-right-order lower-case"
                              onClick={() => navigate("/basicformpage")}
                              style={{
                                padding: "10px 30px",
                                marginBottom: "20px",
                                border: "none",
                              }}
                              disabled={pList?.length >= 5 ? true : false}
                            >
                              Register Now
                            </Button>
                          </div>
                          )} */}

                          <div
                            className="note-block-wrapper"
                            style={{ justifyContent: "center" }}
                          >
                            <div>
                              <b
                                style={{
                                  fontSize: "20px",
                                  marginRight: "15px",
                                }}
                              >
                                Note
                              </b>
                            </div>
                            <div>
                              {globalRun != 1 && (
                                <p style={{ marginBottom: "5px" }} className="ios-lh-raceprice">
                                  <span class="dots-big">
                                    <b>1.</b>
                                  </span>{" "}
                                  <b>
                                    For group registrations i.e. 20 or more
                                    please contact on{" "}
                                    <a href="mailto:registrations@greenheartfulnessrun.com">
                                    registrations@greenheartfulnessrun.com
                                    </a>
                                  </b>
                                </p>
                              )}
                              {selectedCountry === "India" && selectedCity === 1 && (
                                <>
                                <p style={{ marginBottom: "5px" }} className="ios-lh-raceprice">
                                  <span class="dots-big">
                                    <b>2.</b>
                                  </span>{" "}
                                  <b>
                                    BIBs will not be distributed on the race
                                    day. Please collect it on the BIB
                                    distribution day
                                  </b>
                                </p>
                                <p style={{ marginBottom: "5px" }} className="ios-lh-raceprice">
                                <span class="dots-big">
                                  <b>3.</b>
                                </span>{" "}
                                <b>
                                  Please provide your unique runner ID during
                                  BIB collection
                                </b>
                              </p>
                              </>
                              )}
                            {selectedCity !== 1 && (
                                <>
                              <p style={{ marginBottom: "5px" }} className="ios-lh-raceprice">
                                <span class="dots-big">
                                  <b>2.</b>
                                </span>{" "}
                                <b>
                                  Global Run participants will get a
                                  participation certificate (without any km)
                                </b>
                              </p>
                              <p style={{ marginBottom: "5px" }} className="ios-lh-raceprice">
                                <span class="dots-big">
                                  <b>3.</b>
                                </span>{" "}
                                <b>
                                You can run from anywhere or from the Heartfulness Center in your city
                                </b>
                              </p>
                              </>
                            )}
                              {globalRun == 1 && (
                                <p style={{ marginBottom: "5px" }} className="ios-lh-raceprice">
                                  <span class="dots-big">
                                    <b>{`${globalRun == 1 ? "2" : "5"}`}.</b>
                                  </span>
                                  <b>
                                    {" "}
                                    Global Run conducted at Heartfulness Centers
                                  </b>{" "}
                                  <br></br>
                                  {""}
                                  {""}
                                  {""}
                                  {centerList.split(",").map((item, index) => {
                                    return (
                                      <Button className="highlight">
                                        {item}
                                      </Button>
                                    );
                                  })}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="gross-top-set">
                          <img className="img-fluid" src={gross} alt="gross" />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                {/* <div class="text-center">
  
                    <Link
                  className="btn btn-orange btn-right-order lower-case"
                  to="/basicformpage"
                >
                  Proceed
            </Link> 
</div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ToastContainer position="top-right" autoClose={5000} /> */}
      {modalShow === true && (
        <RegisterPopup show={modalShow} onHide={() => closeModal()} islogin={islogin} racePice={"RacePrice"}/>
      )} 
    </>
  );
}
